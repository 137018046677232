import {Router} from "@angular/router";
import {JWT} from "@app/shared/interfaces";
import {inject, Injector} from '@angular/core';
import {AuthService} from "@app/shared/services";
import {httpContextDefaults} from "@app/shared/constants";
import {catchError, filter, finalize} from 'rxjs/operators';
import {AppStateService} from "@app/shared/services/app-state.service";
import {BehaviorSubject, Observable, switchMap, throwError} from 'rxjs';
import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';

const enum RequestHeaderKey {
  AuthToken = 'Authorization',
  RefreshToken = 'X-REFRESH-TOKEN',
  APIKey = 'API-KEY',
  ContentType = 'Content-Type'
}

export const tokenInterceptor: HttpInterceptorFn = (request, next) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const injector = inject(Injector);
  let appStateService = null as any;

  setTimeout(() => appStateService = injector.get(AppStateService))

  const tokenSubject = new BehaviorSubject<JWT | null>(null);
  const refreshTokenInProgress = false;

  const params = request.context.get(httpContextDefaults);

  if (params?.skipAuth) {
    return next(request);
  }

  if (!request.url.includes('refresh-token')) {
    request = addAuthHeader(request, authService);
  }

  if (!request.headers.has('Content-Type') && !(request.body instanceof FormData)) {
    request = request.clone({headers: request.headers.set(RequestHeaderKey.ContentType, 'application/json')});
  }

  return next(request)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !router.url.includes(appStateService.getLngRout('auth')) && !error?.error?.refreshTokenExpired) {
          return refreshToken(request, next, authService, router, tokenSubject, refreshTokenInProgress, appStateService);
        } else {
          return throwError(() => error);
        }
      })
    );
};

const addAuthHeader = (request: HttpRequest<any>, authService: AuthService) => {
  const authHeader = authService.token;
  if (authHeader) {
    return request.clone({
      setHeaders: {[RequestHeaderKey.AuthToken]: 'Bearer ' + authHeader}
    });
  }
  return request;
};

const refreshToken = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
  authService: AuthService,
  router: Router,
  tokenSubject: BehaviorSubject<JWT | null>,
  refreshTokenInProgress: boolean,
  appStateService: AppStateService,
): Observable<HttpEvent<any>> => {

  if (!refreshTokenInProgress) {
    refreshTokenInProgress = true;
    tokenSubject.next(null);

    return authService.refreshToken()
      .pipe(
        switchMap((res: JWT) => {
          refreshTokenInProgress = false;
          tokenSubject.next(res);
          return next(addAuthHeader(request, authService));
        }),
        catchError(() => {
          const urls = [
            appStateService.getLngRout('dashboard'),
            appStateService.getLngRout('account'),
          ];

          if (urls.some((url) => router.url.includes(url))) {
            router.navigate([appStateService.getLngRout('home')]);
          }

          return authService.signOut();
        }),
        finalize(() => refreshTokenInProgress = false)
      );

  } else {
    return tokenSubject
      .pipe(
        filter((res) => res != null),
        switchMap(() => next(addAuthHeader(request, authService)))
      );
  }
};
