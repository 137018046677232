<div class="auth-main-container">
  <div class="auth-container sign-up">
    <div class="left-side">
      <div class="content-part">
        <div class="logo-wrapper">
          <a [routerLink]="appStateService.route_paths | lngRoute: 'home' : appStateService.dateTime">
            <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv logo">
            <span>selfcv</span>
          </a>
        </div>

        <h1 class="mb-30">{{ 'signUp_page.welcome' | translate }}</h1>

        <div class="form-wrapper">
          <form [formGroup]="signupForm" #form="ngForm" (ngSubmit)="onSubmit()">
            <div class="sf-input-wr mb-20">
              <input
                class="sf-input"
                type="text"
                [placeholder]="'placeholder.full_name' | translate"
                formControlName="fullName"
                sfTrim
                [class.error]="form.submitted && signupForm.controls['fullName'].errors">

              <div class="sf-err-wr" *ngIf="form.submitted && signupForm.controls['fullName'].errors as error">
                <div class="sf-input-err" *ngIf="error['required']">
                  <i class="ph ph-warning"></i>
                  {{ 'validation.full_name_required' | translate }}
                </div>

                <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                  <i class="ph ph-warning"></i>
                  {{
                    'validation.full_name_max_length'| translate:{
                      requiredLength: length["requiredLength"],
                      actualLength: length["actualLength"]
                    }
                  }}
                </div>
              </div>
            </div>

            <div class="sf-input-wr mb-20">
              <input
                class="sf-input"
                type="text"
                [placeholder]="'placeholder.email' | translate"
                formControlName="email"
                sfTrim
                [class.error]="form.submitted && signupForm.controls['email'].errors">

              <div class="sf-err-wr" *ngIf="form.submitted && signupForm.controls['email'].errors as error">
                <div class="sf-input-err" *ngIf="error['required']">
                  <i class="ph ph-warning"></i>
                  {{ 'validation.email_required' | translate }}
                </div>

                <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                  <i class="ph ph-warning"></i>
                  {{
                    'validation.email_max_length'| translate:{
                      requiredLength: length["requiredLength"],
                      actualLength: length["actualLength"]
                    }
                  }}
                </div>

                <div class="sf-input-err" *ngIf="error['email']">
                  <i class="ph ph-warning"></i>
                  {{ 'validation.incorrect_email' | translate }}
                </div>
              </div>
            </div>

            <div class="sf-input-wr">
              <input
                type="password"
                class="sf-input"
                autocomplete
                [placeholder]="'placeholder.password' | translate"
                formControlName="password"
                sfTrim
                [class.error]="form.submitted && signupForm.controls['password'].errors">

              <div class="sf-err-wr" *ngIf="form.submitted && signupForm.controls['password'].errors as error">
                <div class="sf-input-err" *ngIf="error['required']">
                  <i class="ph ph-warning"></i>
                  {{ 'validation.password_required' | translate }}
                </div>

                <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                  <i class="ph ph-warning"></i>
                  {{
                    'validation.password_max_length'| translate:{
                      requiredLength: length["requiredLength"],
                      actualLength: length["actualLength"]
                    }
                  }}
                </div>

                <div class="sf-input-err" *ngIf="error['minlength'] as length">
                  <i class="ph ph-warning"></i>
                  {{
                    'validation.password_min_length'| translate: {
                      requiredLength: length["requiredLength"],
                      actualLength: length["actualLength"]
                    }
                  }}
                </div>
              </div>
            </div>

            <div class="btn-part">
              <button class="sf-btn-primary mt-32" [class.loading]="loaderState">
                {{ 'signUp_page.register' | translate }}
              </button>

              <div class="m-10 fs-12">{{ 'signUp_page.or' | translate }}</div>

              <div class="d-flex jc-c mb-32" id="sign-up-google"></div>
            </div>

            <div class="have-not-account">
              <span> {{ 'signUp_page.have_account' | translate }} </span>
              <a
                class="sf-link"
                [routerLink]="appStateService.route_paths | lngRoute: 'sign_in' : appStateService.dateTime"
                queryParamsHandling="preserve">
                {{ 'signUp_page.log_in' | translate }}
              </a>
            </div>

            <div class="sf-input-wr mt-32">
              <div class="sf-checkbox"> <!-- [class.error]="form.submitted && signupForm.controls['terms'].errors" -->
                <!--<input type="checkbox" id="terms" formControlName="terms">-->
                <div class="fs-13 txt-center" [innerHTML]="'signUp_page.agreement' | translate"></div>
              </div>

              <!--<div class="sf-err-wr" *ngIf="form.submitted && signupForm.controls['terms'].errors as error">
                <div class="sf-input-err" *ngIf="error['required']">
                  <i class="ph ph-warning"></i>
                  Terms are required
                </div>
              </div>-->
            </div>

          </form>
        </div>

        <div class="sf-info-banner error ph ph-x-circle mt-30" *ngIf="errorMessage">
          <div>
            <div>{{ 'error' | translate }}</div>
            <p>{{ errorMessage }}</p></div>
        </div>
      </div>
    </div>

    <div class="right-side"></div>
  </div>
</div>
