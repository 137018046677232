import {finalize, Subject, takeUntil} from "rxjs";
import {AppStateService, AuthService} from "@app/shared/services";
import {TranslateModule} from "@ngx-translate/core";
import {HttpErrorResponse} from "@angular/common/http";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {SfTrimDirective} from "@app/shared/directives";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {passwordMatchValidator} from "@app/shared/helpers";
import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {LngRoutePipe} from "@app/shared/pipes";

@Component({
  selector: 'sf-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  imports: [
    ReactiveFormsModule,
    RouterLink,
    NgOptimizedImage,
    NgIf,
    SfTrimDirective,
    TranslateModule,
    LngRoutePipe
  ],
  standalone: true
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly authService = inject(AuthService);
  private readonly activatedRoute = inject(ActivatedRoute);
  protected readonly appStateService = inject(AppStateService);

  public resetPasswordForm = this.formBuilder.group({
    password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(128)]],
    rp_password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(128)]],
    token: [this.activatedRoute.snapshot.queryParams['token'], [Validators.required]]
  }, {validator: passwordMatchValidator()});

  public loaderState = false;
  private token = '';
  public passwordResetBlockState = false;
  public errorMessage = '';
  public readonly destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.appStateService.setPageSeo('reset_password');
    this.validateResetPasswordToken();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  private validateResetPasswordToken(): void {
    this.token = this.activatedRoute.snapshot.queryParams['token'];
    if (!this.token) {
      this.errorMessage = 'Something’s gone wrong, you do not have identification token (It seems you opened form not from an email)';
    }
  }

  public onSubmit(): void {
    if (this.resetPasswordForm.get('token')?.errors?.['required']) {
      this.errorMessage = 'Something’s gone wrong, try again later.';
    }

    if (this.resetPasswordForm.valid) {
      this.changePassword();
    }
  }

  private changePassword(): void {
    const newPassword = this.resetPasswordForm.get('password')?.value;
    const token = this.activatedRoute.snapshot.queryParams['token'];
    this.loaderState = true;
    this.errorMessage = '';

    this.authService.changePassword({newPassword, token})
      .pipe(
        finalize(() => this.loaderState = false),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: () => this.passwordResetBlockState = true,
        error: (err: HttpErrorResponse) => this.errorMessage = err?.error?.reason
      });
  }

}
