import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {AppStateService, AuthService} from "../services";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DashboardGuard {
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);
  private readonly appStateService = inject(AppStateService);

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.authService.isAuthenticated) {
      this.router.navigate(
        [this.appStateService.getLngRout('auth')],
        {queryParams: {...route.queryParams, returnUrl: state.url}}
      );
      return false;
    }

    return this.authService.getMe().pipe(map(() => true));
  }

}

