<sf-header/>

<div class="about-us-container">
  <h1>{{ 'about_us_page.about_us' | translate }}</h1>

  <div class="about-us-content">
    <p class="mb-36" [innerHTML]="'about_us_page.welcome' | translate"></p>

    <div class="mb-36">
      <h2 class="mb-10">{{ 'about_us_page.who_we' | translate }}</h2>
      <div>
        <h4 class="d-flex ai-c">
          <a [routerLink]="appStateService.route_paths | lngRoute: 'home' : appStateService.dateTime" class="d-flex ai-c mr-10">
            <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv">
            <span class="fs-19 ml-6">selfcv</span>
          </a>
          {{ 'about_us_page.hire' | translate }}
        </h4>
      </div>
    </div>

    <div class="mb-36">
      <h4> {{ 'about_us_page.our_mission' | translate }}</h4>
      <div [innerHTML]="'about_us_page.our_mission_desc' | translate"></div>
    </div>

    <div class="mb-36">
      <h4>{{ 'about_us_page.our_values' | translate }}</h4>

      <div>
        <ul>
          <li [innerHTML]="'about_us_page.empowerment' | translate"></li>
          <li [innerHTML]="'about_us_page.innovation' | translate"></li>
          <li [innerHTML]="'about_us_page.excellence' | translate"></li>
          <li [innerHTML]="'about_us_page.personalization' | translate"></li>
        </ul>
      </div>
    </div>

    <div class="mb-36">
      <h4 class="what-we-offer">{{ 'about_us_page.we_offer' | translate }}</h4>

      <div>
        <ul>
          <li [innerHTML]="'about_us_page.customizable_templates' | translate"></li>
          <li [innerHTML]="'about_us_page.easy-to-use' | translate"></li>
          <li [innerHTML]="'about_us_page.ATS' | translate"></li>
          <li [innerHTML]="'about_us_page.cover_letters' | translate"></li>
          <li [innerHTML]="'about_us_page.data_security' | translate"></li>
        </ul>
      </div>
    </div>

  </div>

</div>

<sf-footer/>

