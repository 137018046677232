import {ToFixedPipe} from "@app/shared/pipes";
import {NzModalModule} from "ng-zorro-antd/modal";
import {TranslateModule} from "@ngx-translate/core";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {AppStateService} from "@app/shared/services";
import {NzMessageModule} from "ng-zorro-antd/message";
import {Component, inject, Input, OnInit} from '@angular/core';
import {DatePipe, DecimalPipe, NgForOf} from "@angular/common";
import {PaymentBannerComponent, PremiumPlansComponent} from "@app/shared/ui-kits";

@Component({
  selector: 'sf-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss'],
  imports: [
    NgForOf,
    NzDrawerModule,
    NzModalModule,
    NzMessageModule,
    DatePipe,
    PaymentBannerComponent,
    DecimalPipe,
    ToFixedPipe,
    PremiumPlansComponent,
    TranslateModule
  ],
  standalone: true
})
export class ChoosePlanComponent implements OnInit {
  protected readonly appStateService = inject(AppStateService);

  @Input() public title = 'pricing_page.plans';

  ngOnInit() {
    this.appStateService.setPageSeo('account_choose_plan');
  }

}
