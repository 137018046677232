import {environment} from "@env";
import {parseJwt} from "@app/shared/helpers";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {SfTrimDirective} from "@app/shared/directives";
import {HttpErrorResponse} from "@angular/common/http";
import {NzMessageService} from "ng-zorro-antd/message";
import {JWT, MySocialUser, User} from "@app/shared/interfaces";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {AnalyticsService, AppStateService, AuthService} from "@app/shared/services";
import {AfterViewInit, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {LngRoutePipe} from "@app/shared/pipes";

declare const google: any;

@Component({
  selector: 'sf-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  imports: [
    ReactiveFormsModule,
    RouterLink,
    NgOptimizedImage,
    NgIf,
    SfTrimDirective,
    TranslateModule,
    LngRoutePipe
  ],
  standalone: true
})
export class SignUpComponent implements OnInit, AfterViewInit {
  private readonly router = inject(Router);
  private readonly destroyRef = inject(DestroyRef);
  private readonly formBuilder = inject(FormBuilder);
  private readonly authService = inject(AuthService);
  private readonly activatedRoute = inject(ActivatedRoute);
  protected readonly appStateService = inject(AppStateService);
  private readonly translateService = inject(TranslateService);
  private readonly analyticsService = inject(AnalyticsService);
  private readonly nzMessageService = inject(NzMessageService);

  protected readonly signupForm: FormGroup = this.formBuilder.group({
    fullName: ['', [Validators.required, Validators.maxLength(128)]],
    email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]],
    password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(128)]],
    // terms: [false, [Validators.requiredTrue]]
  });
  protected loaderState = false;
  protected errorMessage = '';

  constructor() {
  }

  ngOnInit() {
    this.appStateService.setPageSeo('sign_up');
    this.analyticsService.track("SIGN UP page visited");
  }

  ngAfterViewInit() {
    this.initializeGoogleSignIn();
  }

  private initializeGoogleSignIn() {
    google.accounts.id.initialize({
      client_id: environment.google_oauth_client_id,
      callback: this.googleSocialSignIn.bind(this)
    });
    google.accounts.id.renderButton(
      document.getElementById("sign-up-google"),
      {theme: "outline", size: "large", text: "continue_with",}  // customization attributes
    );
  }

  private googleSocialSignIn(data: { credential: string; select_by: string; }) {
    const user: MySocialUser = {
      credential: data.credential,
      provider: 'google'
    };

    this.authService.socialSignIn(user)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res: JWT) => {
          // Adding userId so we can track
          const jwtContent = parseJwt(res.accessToken);
          user.id = jwtContent.userId;
          this.getMe();
        },
        error: () => {
          this.nzMessageService.error(
            this.translateService.instant('project_messages.social_login_failed')
          );
        }
      });
  }

  protected onSubmit(): void {
    if (this.signupForm.valid) {
      this.loaderState = true;
      this.signUp(this.signupForm.value);
    }
  }

  private signUp(formValue: User): void {
    this.authService.register(formValue)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.getMe();
        },
        error: (err: HttpErrorResponse) => {
          this.loaderState = false;
          this.errorMessage = err.error.reason;
        }
      });
  }

  private getMe(): void {
    if (this.authService.isAuthenticated) {
      this.authService.getMe()
        .subscribe((res: User) => {
          this.appStateService.user = res;
          this.appStateService.user$.next(res);
          this.router.navigateByUrl(this.returnUrl);
        });
    }
  }

  private get returnUrl(): string {
    return this.activatedRoute.snapshot.queryParams['returnUrl'] || this.appStateService.getLngRout('home');
  }

}
