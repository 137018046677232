import mixpanel from 'mixpanel-browser';
import {routes} from "@app/app-routing";
import en from '@angular/common/locales/en';
import {AppComponent} from "@app/app.component";
import {en_US, NZ_I18N} from "ng-zorro-antd/i18n";
import {importProvidersFrom} from "@angular/core";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {NzMessageService} from "ng-zorro-antd/message";
import {DatePipe, registerLocaleData} from "@angular/common";
import {NZ_CONFIG, NzConfig} from "ng-zorro-antd/core/config";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {bootstrapApplication} from "@angular/platform-browser";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NZ_MODAL_DATA, NzModalService} from "ng-zorro-antd/modal";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {HttpClient, provideHttpClient, withInterceptors, withJsonpSupport} from "@angular/common/http";
import {PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading} from "@angular/router";
import {
  cacheInterceptor,
  errorLogInterceptor,
  notifierInterceptor,
  tokenInterceptor,
  urlPrefixInterceptor
} from "@app/shared/interceptors";


/*Sentry.init({
  dsn: "https://915bd715b0e92d948a6e17385e92ec46@o4507443370983424.ingest.us.sentry.io/4507447541366784",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  release: 'selfcv-ui@1.0.0',
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: environment.env,
});*/


// Near entry of your product, init Mixpanel
mixpanel.init(
  '9dad90d6f2877ce5fab01a7a165ea6d2',
  {
    debug: true,
    track_pageview: false,
    persistence: 'localStorage'
  }
);

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

registerLocaleData(en);

const ngZorroConfig: NzConfig = {
  message: {
    nzMaxStack: 1
  }
};

bootstrapApplication(AppComponent, {
  providers: [
    DatePipe,
    NzNotificationService,
    NzMessageService,
    NzDrawerService,
    NzModalService,

    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'en',
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })
    ),

    /*{
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // showDialog: true, // this show modal in your project to send feedback.
      }),
    },
    {provide: Sentry.TraceService, deps: [Router]},
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },*/

    {provide: NZ_MODAL_DATA, useValue: null},
    {provide: NZ_I18N, useValue: en_US},
    {provide: NZ_CONFIG, useValue: ngZorroConfig},

    provideAnimationsAsync(),

    provideRouter(routes,
      withInMemoryScrolling({scrollPositionRestoration: 'enabled'}),
      withPreloading(PreloadAllModules)
    ),

    provideHttpClient(
      withInterceptors([
        urlPrefixInterceptor,
        tokenInterceptor,
        cacheInterceptor,
        notifierInterceptor,
        errorLogInterceptor
      ]),
      withJsonpSupport()
    ),

  ]
})
  .catch(e => console.error(e));
