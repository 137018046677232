import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {AppStateService, AuthService} from "../services";

export const authGuard = () => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const appStateService = inject(AppStateService);

  if (authService.isAuthenticated) {
    router.navigate([appStateService.getLngRout('home')]);
    return false;
  }
  return true;
}
