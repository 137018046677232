import {NgForOf, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {AppStateService} from "@app/shared/services";
import {SfTrimDirective} from "@app/shared/directives";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NzDatePickerComponent} from "ng-zorro-antd/date-picker";
import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {NzOptionComponent, NzSelectComponent} from "ng-zorro-antd/select";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  CouponBannerComponent,
  FooterComponent,
  HeaderComponent,
  ProjectSocialIconsComponent
} from "@app/shared/ui-kits";
import {
  NzAutocompleteComponent,
  NzAutocompleteOptionComponent,
  NzAutocompleteTriggerDirective
} from "ng-zorro-antd/auto-complete";

@Component({
  selector: 'sf-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss',
  standalone: true,
  imports: [
    HeaderComponent,
    FooterComponent,
    CouponBannerComponent,
    NgForOf,
    NgIf,
    NzAutocompleteComponent,
    NzAutocompleteOptionComponent,
    NzAutocompleteTriggerDirective,
    NzDatePickerComponent,
    NzOptionComponent,
    NzSelectComponent,
    ReactiveFormsModule,
    SfTrimDirective,
    ProjectSocialIconsComponent,
    TranslateModule
  ]
})
export class ContactUsComponent implements OnInit {
  private readonly destroyRef = inject(DestroyRef);
  private readonly formBuilder = inject(FormBuilder);
  private readonly appStateService = inject(AppStateService);

  public readonly contactUsForm = this.formBuilder.group({
    fullName: [null, [Validators.required, Validators.maxLength(128)]],
    email: [
      this.appStateService.user?.email || null,
      [Validators.required, Validators.email, Validators.maxLength(128)]
    ],
    subject: [null, [Validators.maxLength(128)]],
    message: [null, [Validators.required, Validators.maxLength(128)]],
  });

  ngOnInit() {
    this.appStateService.setPageSeo('contact_us');

    this.appStateService.user$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user) => {
        this.contactUsForm.get('email')?.setValue(user?.email || null)
      });
  }

  protected onSubmit() {
    console.log(this.contactUsForm)
  }

}
