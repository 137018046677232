import {Router} from "@angular/router";
import {DatePipe, NgIf} from "@angular/common";
import {finalize, Subject, takeUntil} from "rxjs";
import {SfTrimDirective} from "@app/shared/directives";
import {passwordMatchValidator} from "@app/shared/helpers";
import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {NzMessageModule, NzMessageService} from "ng-zorro-antd/message";
import {FormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {AppStateService, AuthService, UserService} from "@app/shared/services";

@Component({
  selector: 'sf-change-password-page',
  templateUrl: './change-password-page.component.html',
  styleUrls: ['./change-password-page.component.scss'],
  imports: [
    NgIf,
    ReactiveFormsModule,
    SfTrimDirective,
    NzMessageModule,
    DatePipe,
    TranslateModule
  ],
  standalone: true
})
export class ChangePasswordPageComponent implements OnInit, OnDestroy {

  private readonly router = inject(Router);
  public readonly authService = inject(AuthService);
  public readonly userService = inject(UserService);
  private readonly formBuilder = inject(FormBuilder);
  public readonly appStateService = inject(AppStateService);
  public readonly translateService = inject(TranslateService);
  private readonly nzMessageService = inject(NzMessageService);

  public resetPassForm = this.formBuilder.group({
    currentPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(128)]],
    newPassword: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(128)]],
    rp_password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(128)]],
  }, {validator: passwordMatchValidator('newPassword')});

  public loaderState = false;
  public socialLoggedIn = false;
  public passwordFormState = false;

  private readonly destroy$ = new Subject<void>();

  ngOnInit() {
    this.appStateService.setPageSeo('account_security');
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  public onSubmit(): void {
    if (this.resetPassForm.valid) {
      this.updateUserPassword();
    }
  }

  private updateUserPassword(): void {
    const {rp_password, ...value} = this.resetPassForm.value;
    this.loaderState = true;

    this.userService.updateUserPassword({...value, id: this.appStateService.user!.id})
      .pipe(
        finalize(() => this.loaderState = false),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (res: { passwordLess: boolean; passwordChangedAt: string; }) => {

          this.socialLoggedIn = res.passwordLess;
          if (res.passwordChangedAt) {
            this.appStateService.user!.passwordChangedAt = res.passwordChangedAt;
            this.onCancel();
            this.nzMessageService.success(
              this.translateService.instant('project_messages.user_password_updated')
            );
          }
        }
      });
  }

  public onCancel(): void {
    this.resetPassForm.reset();
    this.passwordFormState = false;
  }

  public onSocialReset() {
    this.authService.signOut();
    this.router.navigate(['auth', 'forgot-password']);
  }

}

