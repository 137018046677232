import {AppStateService} from "@app/shared/services";
import {Component, inject, OnInit} from '@angular/core';
import {ChoosePlanComponent} from "@app/layouts/account/pages";
import {FAQSectionComponent} from "@app/layouts/main-layout/pages/sections";

@Component({
  selector: 'sf-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss'],
  imports: [
    ChoosePlanComponent,
    FAQSectionComponent
  ],
  standalone: true
})
export class PricingPageComponent implements OnInit {
  public readonly appStateService = inject(AppStateService);

  ngOnInit() {
    this.appStateService.setPageSeo('pricing');
  }

}
