<div class="reset-main-container">
  <div class="auth-container forgot-password">
    <div class="left-side">
      <div class="content-part">
        <div class="logo-wrapper">
          <a [routerLink]="appStateService.route_paths | lngRoute: 'home' : appStateService.dateTime">
            <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv logo">
            <span>selfcv</span>
          </a>
        </div>

        <ng-container *ngIf="!checkEmailBlockState; else EmailSentRef">
          <h1 class="mb-16">{{ 'forgot_password.forgot_password' | translate }}</h1>
          <p class="sub-title">{{ 'forgot_password.no_worries' | translate }}</p>

          <div class="form-wrapper">
            <form [formGroup]="forgotPasswordForm" #form="ngForm" (ngSubmit)="onSubmit()">

              <div class="sf-input-wr mb-20">
                <input
                  class="sf-input"
                  type="text"
                  [placeholder]="'placeholder.email' | translate"
                  formControlName="email"
                  sfTrim
                  [class.error]="form.submitted && forgotPasswordForm.controls['email'].errors">

                <div class="sf-err-wr" *ngIf="form.submitted && forgotPasswordForm.controls['email'].errors as error">
                  <div class="sf-input-err" *ngIf="error['required']">
                    <i class="ph ph-warning"></i>
                    {{ 'validation.email_required' | translate }}
                  </div>

                  <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                    <i class="ph ph-warning"></i>
                    {{
                      'validation.email_max_length'| translate:{
                        requiredLength: length["requiredLength"],
                        actualLength: length["actualLength"]
                      }
                    }}
                  </div>

                  <div class="sf-input-err" *ngIf="error['email']">
                    <i class="ph ph-warning"></i>
                    {{ 'validation.incorrect_email' | translate }}
                  </div>
                </div>
              </div>

              <div class="btn-part">
                <button class="sf-btn-primary" [class.loading]="loaderState">
                  {{ 'forgot_password.reset-password' | translate }}
                </button>
              </div>

              <div class="have-not-account">
                <span>{{ 'forgot_password.do_not_have' | translate }}</span>
                <a class="sf-link" [routerLink]="appStateService.route_paths | lngRoute: 'sign_up' : appStateService.dateTime">
                  {{ 'forgot_password.sign_up' | translate }}
                </a>
              </div>

            </form>
          </div>
        </ng-container>

        <div class="sf-info-banner error ph ph-x-circle mt-30" *ngIf="errorMessage">
          <div>
            <div>{{ 'error' | translate }}</div>
            <p>{{ errorMessage }}</p>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #EmailSentRef>
  <div class="email-send-banner">
    <h2>{{ 'forgot_password.check_email' | translate }}</h2>

    <p>{{ 'forgot_password.we_sent_password' | translate }}</p>

    <div class="have-not-account">
      <span>{{ 'forgot_password.did_not_receive' | translate }}</span>

      <a class="sf-link" (click)="onResent()" [class.resent]="!resetState">
        {{ 'forgot_password.resend' | translate }}
      </a>
    </div>
  </div>
</ng-template>

