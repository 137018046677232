<div class="auth-main-container">
  <div class="auth-container sign-in">

    <div class="left-side">
      <div class="content-part">
        <div class="logo-wrapper">
          <a [routerLink]="appStateService.route_paths | lngRoute: 'home': appStateService.dateTime">
            <img ngSrc="assets/img/logo.svg" width="24" height="28" alt="selfcv logo">
            <span>selfcv</span>
          </a>
        </div>

        <h1 class="mb-30">{{ 'signIn_page.welcome_back' | translate }}</h1>

        <div class="form-wrapper">
          <form [formGroup]="signInForm" #form="ngForm" (ngSubmit)="onSubmit()">
            <div class="sf-input-wr mb-20">
              <input
                class="sf-input"
                type="text"
                [placeholder]="'placeholder.email' | translate"
                formControlName="email"
                sfTrim
                [class.error]="form.submitted && signInForm.controls['email'].errors">

              <div class="sf-err-wr" *ngIf="form.submitted && signInForm.controls['email'].errors as error">
                <div class="sf-input-err" *ngIf="error['required']">
                  <i class="ph ph-warning"></i>
                  {{ 'validation.email_required' | translate }}
                </div>

                <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                  <i class="ph ph-warning"></i>
                  {{
                    'validation.email_max_length'| translate:{
                      requiredLength: length["requiredLength"],
                      actualLength: length["actualLength"]
                    }
                  }}
                </div>

                <div class="sf-input-err" *ngIf="error['email']">
                  <i class="ph ph-warning"></i>
                  {{ 'validation.incorrect_email' | translate }}
                </div>
              </div>
            </div>

            <div class="sf-input-wr">
              <input
                type="password"
                class="sf-input"
                autocomplete
                [placeholder]="'placeholder.password' | translate"
                formControlName="password"
                sfTrim
                [class.error]="form.submitted && signInForm.controls['password'].errors">

              <div class="sf-err-wr" *ngIf="form.submitted && signInForm.controls['password'].errors as error">
                <div class="sf-input-err" *ngIf="error['required']">
                  <i class="ph ph-warning"></i>
                  {{ 'validation.password_required' | translate }}
                </div>

                <div class="sf-input-err" *ngIf="error['maxlength'] as length">
                  <i class="ph ph-warning"></i>
                  {{
                    'validation.password_max_length'| translate:{
                      requiredLength: length["requiredLength"],
                      actualLength: length["actualLength"]
                    }
                  }}
                </div>

                <div class="sf-input-err" *ngIf="error['minlength'] as length">
                  <i class="ph ph-warning"></i>
                  {{
                    'validation.password_min_length'| translate: {
                      requiredLength: length["requiredLength"],
                      actualLength: length["actualLength"]
                    }
                  }}
                </div>
              </div>
            </div>

            <div class="forgot-pass">
              <a class="sf-link" [routerLink]="appStateService.route_paths | lngRoute: 'forgot_password' : appStateService.dateTime">
                {{ 'signIn_page.forgot-password' | translate }}
              </a>
            </div>

            <div class="btn-part" [ngStyle]="{pointerEvent: loaderState ? 'none' : 'initial'}">
              <button class="sf-btn-primary" [class.loading]="loaderState">
                {{ 'signIn_page.sign_in' | translate }}
              </button>

              <div class="m-10 fs-12">{{ 'signIn_page.or' | translate }}</div>

              <div class="d-flex jc-c mb-32" id="sign-in-google"></div>
            </div>

            <div class="have-not-account">
              <span> {{ 'signIn_page.dont_have_account' | translate }} </span>
              <a
                class="sf-link"
                [routerLink]="appStateService.route_paths | lngRoute: 'sign_up' : appStateService.dateTime"
                queryParamsHandling="preserve">
                {{ 'signIn_page.sign_up' | translate }}
              </a>
            </div>

          </form>
        </div>

        @if (errorMessage) {
          <div class="sf-info-banner error ph ph-x-circle mt-30">
            <div>
              <div>{{ 'error' | translate }}</div>
              <p>{{ errorMessage }}</p></div>
          </div>
        }

      </div>
    </div>

    <div class="right-side"></div>
  </div>
</div>
