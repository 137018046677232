import {Component, inject} from '@angular/core';
import {RouterLink} from "@angular/router";
import {NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {FooterComponent, HeaderComponent} from "@app/shared/ui-kits";
import {LngRoutePipe} from "@app/shared/pipes";
import {AppStateService} from "@app/shared/services";

@Component({
  selector: 'sf-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  imports: [
    HeaderComponent,
    FooterComponent,
    NgOptimizedImage,
    RouterLink,
    TranslateModule,
    LngRoutePipe
  ],
  standalone: true
})
export class PageNotFoundComponent {
  protected readonly appStateService = inject(AppStateService)
}
